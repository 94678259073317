import { AnchorHTMLAttributes, ClassAttributes, HTMLAttributes, forwardRef } from 'react';

const BreadcrumbItemContainer = (props: JSX.IntrinsicAttributes & ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement>) => (
  <div
    className="flex flex-row items-center font-light text-mglGray500 before:mr-2 before:flex before:h-[13px] before:w-2 before:items-center before:justify-center before:pb-[3px] before:text-center before:text-base before:leading-none before:content-['›'] first-of-type:before:content-none"
    {...props}>
    {props.children}
  </div>
);
const BreadcrumbItem = forwardRef<HTMLAnchorElement, JSX.IntrinsicAttributes & ClassAttributes<HTMLAnchorElement> & AnchorHTMLAttributes<HTMLAnchorElement>>(
  (props, ref) => (
    <a className="flex h-4 flex-row items-center text-xs leading-4 text-mglGray500 no-underline" {...props} ref={ref}>
      {props.children}
    </a>
  )
);

export { BreadcrumbItem, BreadcrumbItemContainer };
