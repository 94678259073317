import { useAmp } from 'next/amp';
import Container from '../Container';
import { mglGray } from '@/styles/designSystemColors';
import classNames from '@/utils/className';

interface WrapperProps {
  backgroundColor?: string;
  isOnHeader?: boolean;
  paddingOnBottom?: boolean;
  paddingOnTop?: boolean;
  hasBreadcrumb?: boolean;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  children: JSX.Element[] | JSX.Element;
  className?: string;
}

export default function WrapperContent({
  children,
  backgroundColor,
  isOnHeader = false,
  paddingOnBottom = false,
  paddingOnTop = false,
  hasBreadcrumb = false,
  mobileOnly = false,
  desktopOnly = false,
  className = undefined,
}: WrapperProps) {
  const variants = {
    isOnHeader: {
      true: 'isOnHeader [&.isOnHeader]:p-0',
      false: '',
    },
    paddingOnBottom: {
      true: 'paddingOnBottom [&.paddingOnBottom]:pb-10',
      false: 'pb-0',
    },
    paddingOnTop: {
      true: 'paddingOnTop [&.paddingOnTop]:pt-10',
      false: '',
    },
    mobileOnly: {
      true: 'flex desktop:hidden',
      false: '',
    },
    desktopOnly: {
      true: 'hidden desktop:flex',
      false: '',
    },
  } as const;
  const isAmp = useAmp();

  return (
    <section
      style={{
        backgroundColor: backgroundColor || mglGray.mglGray50,
      }}
      className={classNames(
        'relative flex w-full flex-col flex-wrap content-center justify-center px-0 py-10',
        variants.isOnHeader[isOnHeader.toString()],
        variants.paddingOnBottom[paddingOnBottom.toString()],
        variants.paddingOnTop[paddingOnTop.toString()],
        variants.mobileOnly[mobileOnly.toString()],
        variants.desktopOnly[desktopOnly.toString()]
      )}>
      <Container isAmp={isAmp} hasBreadcrumb={hasBreadcrumb} className={className}>
        {children}
      </Container>
    </section>
  );
}
