import classNames from '@/utils/className';

interface ContainerProps {
  children: JSX.Element[] | JSX.Element;
  isAmp?: boolean;
  hasBreadcrumb?: boolean;
  className?: string;
}

export default function Container({ children, isAmp = false, hasBreadcrumb = false, className = '' }: ContainerProps): JSX.Element {
  const variants = {
    isAmp: {
      true: 'max-w-[calc(100%-2rem)]',
      false: '',
    },
    hasBreadcrumb: {
      true: 'mt-4 desktop:mt-10',
      false: '',
    },
  } as const;

  return (
    <div
      className={classNames(
        'w-full max-w-[calc(100%-2rem)] desktop:max-w-[83.33%]',
        variants.isAmp[isAmp.toString()],
        variants.hasBreadcrumb[hasBreadcrumb.toString()],
        className
      )}>
      {children}
    </div>
  );
}
