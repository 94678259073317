/* eslint-disable react/no-array-index-key */
import getEnv from '@/utils/getEnv';
import { getCurrentHub } from '@sentry/nextjs';
import Link from 'next/link';
import { BreadcrumbItem, BreadcrumbItemContainer } from './styles';

export type BreadcrumbItem = {
  titulo: string;
  url: string;
};

interface BreadcrumbProps {
  breadcrumbList: BreadcrumbItem[];
  isSubDomain?: boolean;
}

export default function Breadcrumb({ breadcrumbList, isSubDomain = false }: BreadcrumbProps) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const breadcrumbSpan = currentSpan && currentSpan.startChild({ op: 'breadcrumb' });

  return (
    <div className="place-content-[center_start] mb-2 flex flex-wrap items-center gap-2">
      <BreadcrumbItemContainer title="Ir para Página Principal do Canaltech">
        <Link href={isSubDomain ? `${getEnv('URL')}/` : '/'} passHref>
          <BreadcrumbItem>Home</BreadcrumbItem>
        </Link>
      </BreadcrumbItemContainer>
      {breadcrumbList &&
        breadcrumbList.map((bItem, i) => (
          <BreadcrumbItemContainer key={bItem.url}>
            <Link href={isSubDomain ? `${getEnv('URL')}${bItem.url}` : bItem.url} passHref>
              <BreadcrumbItem title={`Ir para ${bItem.titulo}`}>{bItem.titulo}</BreadcrumbItem>
            </Link>
          </BreadcrumbItemContainer>
        ))}
      {breadcrumbSpan && breadcrumbSpan.finish()}
    </div>
  );
}
